<mat-card appearance="outlined" class="mat-mr no-gap" [class.expanded-card]="isExpanded" >
    <mat-card-title class="titleRow">
        <!-- <person-chip [display]="tempLoad" size="small" header></person-chip> -->
        <span>
            Invite For Event 
        </span>
        <span>
            <button class="color-icon-button" mat-raised-button [disabled]="selected().length <= 0 || !canSend" (click)="sendInvites()" #tooltip="matTooltip" [matTooltip]="canSend ? 'Email Invitations' : 'Contact us to be able to send invitations'">
                <fa-icon [icon]="sendIcon"></fa-icon>
                Send Invites 
                @if(selected().length > 0){
                    ({{selected().length}})
                }
            </button>
        </span>
        <!-- <person-icon [display]="tempLoad"></person-icon>
        <span>{{tempLoad.prettyName()}}</span> -->
        <div class="widgetIcons">
            <!-- <fa-icon class="resize" [icon]="compressIcon" *ngIf="isExpanded" (click)="compressWidget()"></fa-icon>
            <fa-icon class="resize" [icon]="expandIcon" *ngIf="!isExpanded" (click)="expandWidget()"></fa-icon>
            <fa-icon [icon]="closeIcon" (click)="closeWidget()"></fa-icon> -->
        </div>
    </mat-card-title>
    <mat-card-content>
        <div>
            <contactable-select (selected)="selectionChange($event)" [filters]="filters" [alreadyContacted]="alreadyContacted()" #tabSelection></contactable-select>
        </div>
    </mat-card-content>
</mat-card>